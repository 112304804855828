export default {
  blue: "#0088FF",
  blueLight: "#60b5f8",
  blueDark: "#264D99",
  red: "#0088FF",
  // redLight: "#FCE7EE",
  redLight: "#FAD0DD",
  violet: "#A05EB5",
  green: "#00965E",
  greenLight: "#D0EBE1",
  yellow: "#FFC72C",
  orange: "#ED8B00",
  orangeLight: "#FBE9D0",
  white: "#FFFFFF",
  black: "#000000",
  gray: "#5C6975",
  grayLight: "#AEAEAE",
  grayDark: "#3B4656",
};
